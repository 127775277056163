import React from "react";
import styled from "styled-components";
import { skills } from "../../data/constants";
import { Tilt } from "react-tilt";
import { Element } from "react-scroll";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-top: 25px; */
  position: relative;
  margin-top: -80px;
  padding-top: 80px;
  z-index: 1;
  align-items: center;
  /* justify-contnet: center; */
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const Title = styled.div`
  font-size: 52px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;
const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const SkillsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 50px;
  justify-content: center;
`;

const Skill = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: rgba(17, 25, 40, 0.83);
  border: 1px solid rgba(255, 255, 255, 0.125);
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 16px;
  padding: 18px 36px;
  @media (max-width: 768px) {
    max-width: 400px;
    padding: 10px 36px;
  }

  @media (max-width: 500px) {
    max-width: 330px;
    padding: 10px 36px;
  }
`;

const SkillTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  color: ${({ theme }) => theme.text_secondary};
`;

const SkillList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 20px;
`;
const SkillItem = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 80};
  border: 1px solid ${({ theme }) => theme.text_primary + 80};
  border-radius: 12px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    color: #1f2634;
    transform: scale(1.5);
    transition: all 0.4s ease-in-out;
    box-shadow: 2px 2px 4px #1f2634;
    filter: brightness(1.05);
    background: hsla(271, 100%, 50%, 1);
    background: linear-gradient(
      225deg,
      hsla(271, 550%, 50%, 1) 0%,
      hsla(294, 50%, 50%, 1) 100%
    );
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 12px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    padding: 6px 12px;
  }
`;
const SkillImage = styled.img`
  width: 24px;
  height: 24px;
`;

const Skills = () => {
  return (
    <Element name="Skills" style={{ scrollMarginBottom: "25px" }}>
      <Container id="Skills">
        <Wrapper>
          <Title>Skills</Title>
          <Desc
            style={{
              marginBottom: "40px",
            }}
          >
            Here are some of my skills on which I have been working on for the
            past 3 years.
          </Desc>

          <SkillsContainer>
            {skills.map((skill, index) => (
              <Tilt key={index}>
                <Skill key={`skill-${index}`}>
                  <SkillTitle>{skill.title}</SkillTitle>
                  <SkillList>
                    {skill.skills?.map((item, index_x) => (
                      <SkillItem key={`skill-x-${index_x}`}>
                        <SkillImage src={item.image} />
                        {item.name}
                      </SkillItem>
                    ))}
                  </SkillList>
                </Skill>
              </Tilt>
            ))}
          </SkillsContainer>
        </Wrapper>
      </Container>
    </Element>
  );
};

export default Skills;
